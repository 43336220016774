 
@font-face {
    font-family: 'MillerDisplayRegular';
    src: url('/fonts/MillerDisplayRegular.eot');
    src: url('/fonts/MillerDisplayRegular.eot') format('embedded-opentype'), url('/fonts/MillerDisplayRegular.woff2') format('woff2'), url('/fonts/MillerDisplayRegular.woff') format('woff'), url('/fonts/MillerDisplayRegular.ttf') format('truetype'), url('/fonts/MillerDisplayRegular.svg#MillerDisplayRegular') format('svg');
}

@font-face {
    font-family: 'Azo Sans';
    src: url('/fonts/AzoSans-Regular.eot');
    src: local('Azo Sans Regular'), local('AzoSans-Regular'), url('/fonts/AzoSans-Regular.eot?#iefix') format('embedded-opentype'), url('/fonts/AzoSans-Regular.woff2') format('woff2'), url('/fonts/AzoSans-Regular.woff') format('woff'), url('/fonts/AzoSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Azo Sans Lt';
    src: url('/fonts/AzoSans-Light.eot');
    src: local('Azo Sans Light'), local('AzoSans-Light'), url('/fonts/AzoSans-Light.eot?#iefix') format('embedded-opentype'), url('/fonts/AzoSans-Light.woff2') format('woff2'), url('/fonts/AzoSans-Light.woff') format('woff'), url('/fonts/AzoSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}